import React from "react"
import ReactMarkdown from "react-markdown"
import BackgroundImage from "gatsby-background-image"

import SEO from "../components/seo"
import Navbar from "../components/Navbar/Navbar"

import styles from "./styles/capp.module.css"

const CappSEO = ({ seoTitle, seoDescription, seoImage }) => (
  <SEO
    image={seoImage}
    title={seoTitle}
    description={seoDescription}
    url="https://cloudware.pt/capp"
  />
)

const Mobile = ({ text, image, seoTitle, seoDescription, seoImage, path }) => (
  <div className={`${styles.capp} ${styles.mobile}`}>
    <CappSEO
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      seoImage={seoImage}
    />
    <Navbar path={path} />

    <section className={styles.image}>
      <div className={styles.content}>
        <BackgroundImage fluid={image.fluid} className={styles.right} />
      </div>
    </section>
    <section className={styles.text}>
      <div className={styles.content}>
        <div className={styles.left}>
          <img
            src={require("../img/CAPP logo.png")}
            alt="CAPP Logo"
            className={styles.logo}
          />
          <ReactMarkdown source={text} />
          <img
            className={styles.stores}
            src={require("../img/stores.png")}
            alt="App Store e Google Store"
          />
        </div>
      </div>
    </section>
  </div>
)
const Desktop = ({ text, image, seoTitle, seoDescription, seoImage, path }) => (
  <div className={`${styles.capp} ${styles.desktop}`}>
    <div className={styles.background}></div>
    <CappSEO
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      seoImage={seoImage}
    />

    <Navbar path={path} transparent />

    <section className={styles.page}>
      <div className={styles.content}>
        <div className={styles.left}>
          <img
            src={require("../img/CAPP logo.png")}
            alt="CAPP Logo"
            className={styles.logo}
          />
          <ReactMarkdown source={text} />
          <img
            className={styles.stores}
            src={require("../img/stores.png")}
            alt="App Store e Google Store"
          />
        </div>
        <BackgroundImage fluid={image.fluid} className={styles.right} />
      </div>
    </section>
  </div>
)

const CAAPPageComponent = ({
  text,
  image,
  seoTitle,
  seoDescription,
  seoImage,
  ...props
}) => (
  <>
    <Desktop
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      seoImage={seoImage}
      image={image}
      text={text}
      {...props}
    />
    <Mobile
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      seoImage={seoImage}
      image={image}
      text={text}
      {...props}
    />
  </>
)

export default CAAPPageComponent
