import React from "react"
import { graphql, StaticQuery } from "gatsby"

import CAAPPageComponent from "../page-components/CAAPPageComponent"

const imageSrcFormatter = (imageSrc, type = "fixed") =>
  (imageSrc &&
    imageSrc.childImageSharp &&
    imageSrc.childImageSharp[type] &&
    imageSrc.childImageSharp[type].src) ||
  ""

const cappQuery = graphql`
  query {
    page: markdownRemark(fields: { slug: { eq: "/pages/capp/" } }) {
      frontmatter {
        text
        featuredImage {
          childImageSharp {
            fixed(width: 800, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        seoTitle
        seoDescription
        seoImage {
          childImageSharp {
            fixed(width: 480, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

const CAAPPage = props => (
  <StaticQuery
    query={cappQuery}
    render={data => {
      const {
        page: {
          frontmatter: {
            text,
            seoTitle,
            seoDescription,
            seoImage,
            featuredImage,
          },
        },
      } = data

      return (
        <CAAPPageComponent
          text={text}
          image={imageSrcFormatter(featuredImage)}
          seoTitle={seoTitle}
          seoDescription={seoDescription}
          seoImage={imageSrcFormatter(seoImage)}
          {...props}
        />
      )
    }}
  />
)

export default CAAPPage
